<template>
<div class="card">
  <div class="card-header bg-white">
    <h6 class="card-title font-weight-semibold">Form {{ $parent.objName }} Penggunaan Obat </h6>
  </div>

  <validation-observer ref="VFormDPFarmasi">
    <b-form @submit.prevent="doSubmit()">
      <div class="card-body">
        <div class="row">
          <div class="col-12 mt-2">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan</h6>
              </div>

              <div class="card-body p-3">
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <table class="table table-striped table-sm table-hover table-bordered">
                        <thead>
                          <tr>
                            <th width="48">Aksi</th>
                            <th width="160">Jenis</th>
                            <th width="320">Nama</th>
                            <th width="150">Jumlah</th>
                            <th width="210">Frekuensi</th>
                            <th>Keterangan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                            <td>
                              <a href="javascript:;" @click="removeObat(v,k)" class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i class="icon-trash"></i></a>
                            </td>
                            <td>
                              <v-select placeholder="Pilih Item" v-model="v.ardpfar_jenis" :options="$parent.Config.mr.configResepRanap" label="text" @input="changeJenis($event,k)" :reduce="v=>v.value">
                              </v-select>
                              <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi" v-model="v.ardpfar_jenis" :rules="{required : 1}" />
                            </td>
                            <td>
                              <div class="d-flex">
                                <template v-if="v['ardpfar_jenis'] == 1">
                                  <v-select style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen" @search="filterObat($event,k);countLen();" v-model="v.ardpfar_item_id" :options="mObatPreferensi" label="text" @input="selectJumlahSatuan($event,k)" :clearable="true" :reduce="v=>v.value" :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                    <template slot="selected-option">
                                      <span :class="isAlergiLabel(v.ardpfar_nama)?'text-warning':''">{{ v.ardpfar_nama }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                      <span :class="isAlergiLabel(option.text)?'text-warning':''" v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                      <span :class="isAlergiLabel(option.text)?'text-warning':''" v-else>{{ option.text }}</span>
                                    </template>
                                    <template #list-footer>
                                      <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <b-button @click="changePageObat(v.currentPage||1,k,'min','resepDokter')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-left22 text-white"></i></b-button>
                                          <small>Halaman {{ v.currentPage||1 }} dari {{ ceilData(v.totalRows/10) }}</small>
                                          <b-button @click="changePageObat(v.currentPage||1,k,'plus','resepDokter')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                      </li>
                                    </template>
                                  </v-select>
                                  <br />
                                </template>

                                <template v-else-if="v['ardpfar_jenis'] == 2">
                                  <v-select style="flex: 1;" class="med-selection select-paging select-custom-height" placeholder="Pilih Item" v-model="v.ardpfar_item_id" :options="mAlatKesehatan" label="text" @input="selectJumlahSatuanAlkes($event,k)" :clearable="true" :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                      <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                      <span>{{ option.text }}</span>
                                    </template>
                                  </v-select>
                                </template>

                                <template v-else-if="v['ardpfar_jenis'] == 3">
                                  <v-select style="flex: 1;" class="med-selection select-paging select-custom-height" placeholder="Pilih Item" v-model="v.ardpfar_item_id" :options="mRacikan" label="text" @input="selectJumlahSatuanRacikan($event,k)" :clearable="true" :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                      <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                      <span>{{ option.text }}</span>
                                    </template>
                                  </v-select>
                                </template>

                                
                                <template v-else-if="v['ardpfar_jenis'] == 5">
                                  <v-select style="flex: 1;" class="med-selection select-paging select-custom-height" placeholder="Pilih Item" v-model="v.ardpfar_item_id" :options="mAlatInfus" label="text" @input="selectJumlahSatuanInfus($event,k)" :clearable="true" :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                      <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                      <span>{{ option.text }}</span>
                                    </template>
                                  </v-select>
                                </template>

                                <a href="javascript:;" v-if="v['ardpfar_jenis'] !== 3" @click="v['ardpfar_item_id'] = 99999; v['ardpfar_nama'] = 'Lainnya'" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                              </div>
                              <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi" v-model="v['ardpfar_item_id']" :rules="{required : 1}" />

                              <div v-if="v['ardpfar_item_id'] == 99999">
                                <b-form-input type="text" v-model="v['ardpfar_item_lainnya']" class="form-control" id="itemCount" />
                                <VValidate :name="'Item Lainnya '+(k+1)" message="Nama Item Lainnya Harus Diisi" v-model="v['ardpfar_item_lainnya']" :rules="{required : 1}" />
                              </div>

                              <a v-if="v.ardpfar_jenis == 3 && v['ardpfar_item_id']" href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title="" data-toggle="modal" data-target="#modalRacikanDetail" class="font-weight-semibold border-bottom" data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                              <small class="text-warning" v-if="isAlergiLabel(v.ardpfar_nama) ">Obat ini termasuk dalam
                                kategori alergi</small>
                            </td>
                            <td>
                              <div class="input-group">
                                <b-form-input type="text" v-model="v['ardpfar_jumlah']" :formatter="Formatter.number" class="form-control" id="itemCount" />
                                <div class="input-group-append">
                                  <span class="input-group-text">{{v['ardpfar_satuan']}}</span>
                                </div>
                              </div>

                              <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi" v-model="v['ardpfar_jumlah']" :rules="{required : 1}" />
                            </td>
                            <td>
                              <span class="d-flex">
                                <v-select style="flex: 1;" class="med-selection" placeholder="Pilih Frekuensi" v-model="v.ardpfar_frekuensi" :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                                </v-select>
                                <a href="javascript:;" @click="v['ardpfar_frekuensi'] = 99999" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                              </span>
                              <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi" v-model="v['ardpfar_frekuensi']" v-if="v.ardpfar_jenis != 2" :rules="{required : 1}" />

                              <div v-if="v['ardpfar_frekuensi'] == 99999">
                                <b-form-input type="text" v-model="v['ardpfar_frekuensi_lainnya']" class="form-control" id="itemCount" />
                                <VValidate :name="'Frekuensi Lainnya '+(k+1)" message="Frekuensi Lainnya Harus Diisi" v-if="v.ardpfar_jenis != 2" v-model="v['ardpfar_frekuensi_lainnya']" :rules="{required : 1}" />
                              </div>
                            </td>
                            <td>
                              <b-form-textarea v-model="v.ardpfar_keterangan" name="itemNotes" id="itemNotes" rows="2" placeholder="cth. obat dihabiskan" class="form-control"></b-form-textarea>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td class="text-center" colspan="99">
                              <a href="javascript:;" @click="openObat()" class="btn alpha-blue">
                                <i class="icon-plus2 mr-1 align-middle"></i>
                                <span class="align-middle">Tambah Resep</span>
                              </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Nama &amp; Tanda Tangan Apoteker <span class="text-danger">*</span></label>
              <b-form-input placeholder="Nama Apoteker" v-model="row.nama_petugas" />
              <VValidate name="Nama" v-model="row.nama_petugas" :rules="mrValidation.nama_petugas" />

              <div class="signing-element mt-1" data-target-id="signature">
                <VueSignaturePad class="sign-container" width="100%" height="160px" ref="signature" @onend="onEnd('signature')" />

                <VValidate name="Tanda Tangan" v-model="row.signature" :rules="mrValidation.signature" />

                <div class="mt-2">
                  <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" @click="undoTTD('signature','signature')">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>
                  <button type="button" @click="output('signature','signature')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                    <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="text-right">
          <button type="button" class="btn" @click="$router.push({ name: $parent.$parent.modulePage })">Kembali</button>
          <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </b-form>
  </validation-observer>
</div>
</template>

<script>
import Formatter from '@/libs/Formatter.js'
import Gen from '@/libs/Gen'
import {
  stringSimilarity
} from "string-similarity-js"
import $ from 'jquery'

const _ = global._

export default {
  props: {},
  components: {},
  data() {
    return {
      formData: {
        pendidikan_kesehatan: []
      },
      rowRacikan: {},
      obatLen: 0,
    }
  },

  mounted() {
    this.setSignature(this.row.signature)
  },

  methods: {
    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
          "/do/" + this.$parent.modulePage, {
            data: dataPost
          },
          "POST"
        )
      }
    }, 1000),
    isAlergiLabel(nama) {
      let data = []
      for (let i = 0; i < (this.row.alergi || []).length; i++) {
        if (this.row.alergi[i]['jenis'] == 'Obat') {
          data.push(this.row.alergi[i]['name'])
        }
      }
      let isWarning = 0
      for (let i = 0; i < (data || []).length; i++) {
        let result = stringSimilarity(nama || "", data[i])
        if (result > 0.3) isWarning += 1
      }
      return isWarning
    },
    countLen() {
      setTimeout(() => {
        this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    removeObat(v, k) {
      if (v.id) {
        let data = {
          type: 'remove-resep-dokter',
          id: this.$route.params.pageSlug
        }
        Gen.apiRest(
          "/do/" + this.$parent.modulePage, {
            data: data
          },
          "POST"
        ).then(res => {
          this.resepDokter.splice(k, 1)
        })
      } else {
        this.resepDokter.splice(k, 1)
      }
    },
    changePageObat(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-obat',
        page: page,
        idDokter: this.rowReg.aranr_dokter_id,
        search: this[col][k]['search']
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mObatPreferensi = res.data.data
      })
    },

    openObat(v = null) {
      let data = {
        ardpfar_aranr_id: this.rowReg.aranr_id,
        ardpfar_jenis: 1,
        ardpfar_item_id: null,
        ardpfar_nama: null,
        ardpfar_jumlah: null,
        ardpfar_satuan: null,
        ardpfar_frekuensi: null,
        ardpfar_frekuensi_lainnya: null,
        ardpfar_keterangan: null,
        ardpfar_code: null,
        ardpfar_is_alergi: null,
        ardpfar_signa1: null,
        ardpfar_signa2: null,
        id: null
      }
      if (!this.row.id) {
        data.type = 'add-resep-dokter'
        Gen.apiRest(
          "/do/" + this.$parent.modulePage, {
            data: data
          },
          "POST"
        ).then(res => {
          this.apiGetForm()
        })
      } else {
        this.resepDokter.push(data)
      }

    },
    resetObat() {
      this.loadingOverlay = true
      let data = {
        id: this.row.ardpfar_id,
        type: 'get-resep-dokter'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.resepDokter = resp.data
        this.loadingOverlay = false
      })
    },
    addObat() {
      this.rowRacikan.ardpfar_racikan.push({
        value: null,
        dosis: null,
        satuan_dosis: null,
        jumlah: null,
        satuan_jumlah: null,
      })
    },
    ceilData(value) {
      return Math.ceil(value)
    },
    getObat(val) {
      let index = this.mObat.findIndex(x => x.value == val)
      if (index !== -1) {
        return this.mObat[index]['text']
      } else {
        return null
      }
    },

    getMasterObat(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.value
      })
      return _.filter(this.mObat, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },
    selectObat(e, k) {
      let index = this.mObat.findIndex(x => x.value == e)
      if (index !== -1) {
        let data = this.mObat[index]
        this.rowRacikan.ardpfar_racikan[k]['dosis'] = data.dosis
        this.rowRacikan.ardpfar_racikan[k]['satuan_dosis'] = data.satuan_dosis
        this.rowRacikan.ardpfar_racikan[k]['satuan_jumlah'] = data.satuan
      }
    },
    changeJenis(e, k) {
      this.resepDokter[k].ardpfar_item_id = null
      this.resepDokter[k].ardpfar_frekuensi = null
      this.resepDokter[k].ardpfar_frekuensi_lainnya = null
    },
    filterObat: _.debounce(function (e, k) {
      if (e) {
        this.resepDokter[k]['search'] = e
        let id = []
        for (let i = 0; i < (this.resepDokter || []).length; i++) {
          if (this.resepDokter[i]['ardpfar_item_id'] && this.resepDokter[i]['ardpfar_jenis'] == 1) {
            id.push(this.resepDokter[i]['ardpfar_item_id'])
          }
        }
        Gen.apiRest('/do/' + 'RanapResumeMedis', {
          data: {
            type: 'select-obat',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mObatPreferensi = res.data.data
          this.$set(this.resepDokter[k], 'currentPage', 1)
          this.$set(this.resepDokter[k], 'totalRows', res.data.totalRows)
        })
      }
    }, 10),
    selectJumlahSatuan(e, k) {
      let data = {
        id: e,
        type: 'get-by-obat'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].ardpfar_satuan = res.data.satuan
        this.resepDokter[k].ardpfar_nama = res.data.nama
        this.resepDokter[k].ardpfar_racikan = null

        this.resepDokter[k].ardpfar_dosis = res.data.dosis
        this.resepDokter[k].ardpfar_satuan_dosis = res.data.satuan_dosis
        this.resepDokter[k].ardpfar_item_lainnya = null

        this.isAlergi(res.data.nama, k)
      })
    },
    selectJumlahSatuanInfus(e, k){
      let data = {
        id: e,
        type: 'get-by-infus'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].ardpfar_satuan = res.data.satuan
        this.resepDokter[k].ardpfar_nama = res.data.nama
        this.resepDokter[k].ardpfar_racikan = null

        this.resepDokter[k].ardpfar_dosis = null
        this.resepDokter[k].ardpfar_satuan_dosis = null
        this.resepDokter[k].ardpfar_frekuensi = null
        this.resepDokter[k].ardpfar_frekuensi_lainnya = null
        this.resepDokter[k].ardpfar_item_lainnya = null
      })
    },
    selectJumlahSatuanAlkes(e, k) {
      let data = {
        id: e,
        type: 'get-by-alkes'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].ardpfar_satuan = res.data.satuan
        this.resepDokter[k].ardpfar_nama = res.data.nama
        this.resepDokter[k].ardpfar_racikan = null

        this.resepDokter[k].ardpfar_dosis = null
        this.resepDokter[k].ardpfar_satuan_dosis = null
        this.resepDokter[k].ardpfar_frekuensi = null
        this.resepDokter[k].ardpfar_frekuensi_lainnya = null
        this.resepDokter[k].ardpfar_item_lainnya = null
      })
    },
    selectJumlahSatuanRacikan(e, k) {
      let data = {
        id: e,
        type: 'get-by-racikan'
      }
      Gen.apiRest(
        "/do/" + 'RanapResumeMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.resepDokter[k].ardpfar_satuan = res.data.satuan
        this.resepDokter[k].ardpfar_nama = res.data.nama
        this.resepDokter[k].ardpfar_racikan = res.data.data

        this.resepDokter[k].ardpfar_dosis = null
        this.resepDokter[k].ardpfar_satuan_dosis = null
        this.resepDokter[k].ardpfar_frekuensi = null
        this.resepDokter[k].ardpfar_frekuensi_lainnya = null
        this.resepDokter[k].ardpfar_item_lainnya = null
      })
    },
    isAlergi(nama, k) {
      let data = []
      for (let i = 0; i < (this.row.alergi || []).length; i++) {
        if (this.row.alergi[i]['jenis'] == 'Obat') {
          data.push(this.row.alergi[i]['name'])
        }
      }
      let isWarning = 0
      for (let i = 0; i < (data || []).length; i++) {
        let result = stringSimilarity(nama || "", data[i])
        if (result > 0.3) isWarning += 1
      }

      this.resepDokter[k]['ardpfar_is_alergi'] = isWarning ? "Y" : "N"
    },

    setSignature(signature) {
      setTimeout(() => {
        if (signature) this.$refs['signature'].fromDataURL(signature)
      }, 1000)
    },
    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs, field) {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this.row[field] = data
      }
    },

    checkDataRow(value, keyData) {
      const indexKeyData = this.formData[keyData].findIndex(dt => dt.value === value)
      return indexKeyData >= 0
    },

    doSubmit() {
      this.$refs['VFormDPFarmasi'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = {
              ...this.row,
              ...this.formData
            }
            this.$parent.doSubmit({
              ...payload,
              type: 'update-discharge-planning'
            })
          }
        })
      })
    }
  },

  computed: {
    row() {
      return this.$parent.row
    },
    rowReg() {
      return this.$parent.rowReg
    },
    resepDokter() {
      return this.$parent.resepDokter
    },

    mrValidation() {
      return this.$parent.mrValidation
    },

    mrPendidikanKesehatanGroups(){
      return _.chunk(this.$parent.mrPendidikanKesehatan, 2)
    },

    Formatter() {
      return Formatter
    },

    mObatPreferensi() {
      return this.$parent.mObatPreferensi
    },
    mDosis() {
      return this.$parent.mDosis
    },
    mAlatKesehatan() {
      return this.$parent.mAlatKesehatan
    },
    mAlatInfus() {
      return this.$parent.mAlatInfus
    },
    mrDokter() {
      return this.$parent.mrDokter
    },
  },

  watch: {
    'row.signature'(v) {
      this.setSignature(v)
    },
    resepDokter: {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    }
  }
}
</script>
