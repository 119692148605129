<template>
<div class="content-wrapper">
  <div class="pt-0 content" v-if="isList">
    <bo-page-title />
    <div class="card">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <h5 class="card-title font-weight-semibold">Pasien {{ objName }}</h5>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-lg-9">
            <div class="row gx-1">
              <div class="col-md">
                <div class="row gx-1">
                  <div class="col-md-auto">
                    <div class="form-group mb-0">
                      <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp" :options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ranap_bangsal" :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>No. Kedatangan</th>
              <th>Nama/No KTP</th>
              <th>Dokter DPJP</th>
              <th>No RM</th>
              <th>Ruangan</th>
              <th>Tanggal lahir</th>
              <th>Usia</th>
              <th>PPJP</th>
              <th>Status</th>
              <th width="120">Aksi</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td><span class="font-weight-semibold">{{ v.aranr_reg_code }}</span><br><span class="text-primary">PASIEN BARU</span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right title="Lihat Informasi Perawatan Pasien">{{ v.ap_fullname || "-" }}</a>
                    <small>{{ v.ap_code || "-" }}</small>
                  </div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>

                <td>{{ v.bu_full_name }}</td>
                <td>
                  <div class="font-weight-semibold">{{ v.ap_code || "-" }}</div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  <div class="font-weight-semibold">{{ v.ap_dob | moment("DD MMMM YYYY") }}</div>
                </td>
                <td> {{v.ap_usia_with_ket || "-"}} <span class="text-success font-weight-semibold">{{ v.ap_gol_usia }}</span></td>
                <td>{{ v.nama_ppjp || "-" }}</td>
                <td>
                  <span class="badge bg-teal mb-1 text-uppercase" v-if="v.ardpfa_status == 'SELESAI'">
                    Selesai
                  </span>
                  <span class="badge bg-info mb-1 text-uppercase" v-else>
                    Menunggu
                  </span>
                </td>
                <td>
                  <a href="javascript:;" v-b-tooltip.hover.right title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-info border-info" @click="openDetail(v)" v-if="moduleRole('view')">
                    <i class="icon-file-eye"></i>
                  </a>

                  <router-link :to="{ name: $route.name, params: { pageSlug: v.ardpfa_id }, query: { idRegistrasi: v.aranr_id } }" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right title="Atur Discharge Planning" v-if="moduleRole('edit') && v.ardpfa_status == 'MENUNGGU'">
                    <i class="fa-solid icon-pencil4"></i>
                  </router-link>

                </td>
              </tr>
            </template>

          </tbody>
          <tbody v-if="data.data.length == 0">

            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal id="detailData" size="lg" hide-footer title="Discharge Planning Penggunaan Obat">
      <div class="row">
        <template v-if="dataModal.ardpfa_status == 'SELESAI'">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="320">Jenis</th>
                    <th width="320">Nama</th>
                    <th width="150">Jumlah</th>
                    <th width="210">Frekuensi</th>
                    <th>Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in dataModal.dataResep" :key="`dr-${k}`">
                    <td>
                      {{ v.mjo_name }}
                    </td>
                    <td>
                      {{ v.ardpfar_nama }}
                    </td>
                    <td>{{ v.ardpfar_jumlah }} {{ v.ardpfar_satuan }}</td>
                    <td>{{ v.mdo_name }}</td>
                    <td>{{ v.ardpfar_keterangan || '-' }}</td>
                  </tr>

                  <tr v-if="!(dataModal.dataResep||[]).length">
                    <td colspan="5">
                      <p class="mb-0 text-center">
                        Tidak ada data resep obat
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-auto text-center mt-2">
            <span>Mengetahui, <br>
              ({{ dataModal.nama_petugas }})</span>
            <div class="mt-2">
              <img :src="dataModal.signature" id="ttdPengirim" height="80" alt="">
            </div>
          </div>

          <div class="col-md-12" v-if="moduleRole('edit')">
            <div class="text-center mt-2">
              <router-link :to="{ name: $route.name, params: { pageSlug: dataModal.ardpfa_id }, query: { idRegistrasi: dataModal.aranr_id } }">
                <b-button variant="outline-success">
                  <i class="icon-pencil4"></i> Ubah Discharge Planning
                </b-button>
              </router-link>
            </div>
          </div>

        </template>

        <div class="col-md-12" v-else>
          <b-alert show variant="danger">
            <strong>Perhatian!</strong> Silakan atur discharge planning terlebih dahulu
          </b-alert>

          <div v-if="moduleRole('edit')" class="text-center mt-2">
            <router-link :to="{ name: $route.name, params: { pageSlug: dataModal.ardpfa_id }, query: { idRegistrasi: dataModal.aranr_id } }">
              <b-button variant="outline-success">
                <i class="icon-pencil4"></i> Atur Discharge Planning
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <span v-else>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.idRegistrasi" /> 

    <div class="content">
      <Form />
    </div>
  </span>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Form from './Form.vue'
import GlobalVue from '@/libs/Global.vue'

import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components: {
    PatientInfo,
    Form
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
      },

      dataModal: {},
      row: {},
      rowReg: {},

      mrPendidikanKesehatan: [],
      mDPJP: [],
      mRanapBangsal: [],
      mrValidation: {},
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      resepDokter: [],
      mObatPreferensi: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mrDokter: [],
      mRacikan: [],
    }
  },

  mounted() {
    if (this.isList) {
      this.apiGet()
    } else {
      this.getPatientInfo()
      this.getForm()
    }
  },

  methods: {
    doResetData(){
      this.doReset()
      this.dateRange =  {
        startDate: new Date(),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),

    openDetail(data) {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_discharge_planning: data.ardpfa_id,
            id_registrasi: data.aranr_id,
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', {
          ...data,
          ...res.data.data,
          dataResep: res.data.dataResep
        })
      })
    },

    getForm() {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_discharge_planning: this.$route.params.pageSlug,
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.idRegistrasi
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    doSubmit(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: this.modulePage
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    }
  },

  computed: {
    pendidikanKesehatanGroups() {
      return _.chunk(this.dataModal.pendidikan_kesehatan, 3)
    }
  },
  watch: {
    $route() {
      if (this.isList) {
        this.apiGet()
      } else {
        this.getPatientInfo()
        this.getForm()
      }
    }
  },
}
</script>
